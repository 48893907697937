import React, { ReactNode, useEffect, useRef } from "react";

interface IProps {
    children: ReactNode[];
}

export function ProductBarWrapper(props: IProps) {
    const ref = useRef(null);

    useEffect(() => {
        const onScroll = () => {
            if (!ref.current) return;
            const wrapper = ref.current as any as HTMLDivElement;
            const scrollTop = window.scrollY;
            const height = wrapper.offsetHeight;
            const offset = wrapper.offsetTop;
            const percentage = (scrollTop - offset) / (height - window.innerHeight);
            const count = wrapper.children.length;
            const breakAt = (1 + count * 0.05) / count;
            const active = parseInt((percentage / breakAt).toString());

            Array.from(wrapper.children).forEach((child, index) => {
                if (index === active) {
                    child?.classList.add('active');
                } else {
                    child?.classList.remove('active');
                }
            });
        };
        document.addEventListener('scroll', onScroll);

        return () => {
            document.removeEventListener('scroll', onScroll);
        }
    }, [ref])

    return (
        <div className="product-bar-wrapper-wrapper" ref={ref}>
            {props.children}
        </div>
    )
}
