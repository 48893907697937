import React from "react";

type InterestOption = 'Cursusplanning' | 'Inspecties' | 'Verhuur';
interface SidebarState {
    interest?: InterestOption;
    setInterest: any;
}

export const InterestStateContext = React.createContext<SidebarState>({
    setInterest: () => {},
});
