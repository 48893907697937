import React, { useEffect, useState } from "react";
import { IMenuItem } from "../../Interfaces/IMenuItem";
import { getLocalStorage, setLocalStorage } from "../../helpers/localStorage";
import { wp } from "../../Factories/Wordpress";
import { Link } from "react-router-dom";
import { OutboundLink } from "react-ga";

interface IProps {
    menuName?: string;
    className?: string;
    children?: any;
}

export function Menu(props: IProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [menu, setMenu] = useState<IMenuItem[]>(props.children);

    useEffect(() => {
        if (!props.menuName) return;
        if (!isLoading && !menu) {
            setIsLoading(true);
            const placeMenu = (result: false|IMenuItem[]) => {
                if (!result) return;
                setLocalStorage(`cached-${props.menuName}`, result);
                setMenu(result);
            };

            const cached = getLocalStorage(`cached-${props.menuName}`);
            const priority = cached ? 10 : 1;
            if (cached) {
                const result = cached as IMenuItem[];
                placeMenu(result);
            }
            wp().menus.get({menu: props.menuName}, undefined, priority).then(placeMenu)
                .finally(() => {
                    setIsLoading(false);
                })
        }
    }, [isLoading, menu, props.menuName, props.children]);

    const classes = [props.className];

    if (isLoading) {
        classes.push('is-loading');
    }

    return (
        <ul className={classes.join(' ')}>
            {(menu ?? []).map((item, index) => {
                let link = <Link to={item.url ?? '#'}>{item.title}</Link>;
                if ((item.url ?? '').includes('://')) {
                    link = <OutboundLink
                        eventLabel={`Go to ${item.url}`}
                        to={item.url ?? ''}
                        target={'_blank'}>
                        {item.title}
                    </OutboundLink>
                }
                return (<li key={index} className={item.subMenu ? 'has-sub' : ''}>
                    {link}
                    {item.subMenu ? (<>
                        <Menu>{item.subMenu}</Menu>
                    </>) : null}
                </li>);
            })}
        </ul>
    )
}
