import React, { useEffect, useState } from "react";
import "./TopBar.scss";
import { Logo } from "../Logo/Logo";
import { Button } from "../Button/Button";
import { SidebarStateContext } from "../../context/SidebarStateContext";
import { Menu } from "../Menu/Menu";
import { useLocation } from 'react-router-dom';

interface IProps {

}

export function TopBar(props: IProps) {
    let location = useLocation();
    const [barShowing, setBarShowing] = useState(window.location.pathname !== '/');
    const [menuOpen, setMenuOpen] = useState(false);
    const [path, setPath] = useState(location.pathname);

    useEffect(() => {
        const handleScroll = () => {
            if (window.location.pathname !== '/') return;
            const doc = document.documentElement;
            const top = doc.scrollTop  - (doc.clientTop || 0);
            setBarShowing(top > 100);
        };
        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    }, [setBarShowing]);

    useEffect(() => {
        setPath(location.pathname);
    }, [location])
    useEffect(() => {
        if (path !== '/') {
            setBarShowing(true);
        }
        setMenuOpen(false);
    }, [path])


    const classes = ['top-bar-wrapper', ...(barShowing ? ['bar-showing'] : []), ...(menuOpen ? ['menu-open'] : [])];

    return (
        <div className={classes.join(' ')}>
            <div className="top-bar-contents">
                <nav>
                    <Menu menuName={'top-menu'} className={'top-menu-wrapper'} />
                </nav>
                <div className="small-logo-wrapper">
                    <Logo />
                </div>
                <div className="cta-wrapper">
                    <div className="phone-wrapper">
                        0321 31 77 99
                    </div>
                    <SidebarStateContext.Consumer>
                        {({isOpen, toggleOpen}) => (
                            <Button onClick={() => toggleOpen(!isOpen)}>Contact</Button>
                        )}

                    </SidebarStateContext.Consumer>
                </div>
                <div className="menu-button" onClick={() => setMenuOpen(!menuOpen)}>
                    <span />
                    <span />
                    <span />
                </div>
            </div>
        </div>
    )
}
