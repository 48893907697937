import React, { ReactNode } from "react";
import "./PageHeader.scss";

interface IProps {
    children?: ReactNode;
}

export function PageHeader(props: IProps) {
    return (
        <div className="page-header-wrapper">
            {props.children}
        </div>
    )
}
