type InterestChoice = 'Cursusplanning' | 'Inspecties' | 'Verhuur';

export interface ContactFormState {
    companyName: string;
    firstName: string;
    lastName: string;
    interest: InterestChoice[];
    phone: string;
    email: string;
    message: string;
}

export class ContactFormHelper {
    static createFormState(): ContactFormState {
        return {
            companyName: '',
            firstName: '',
            lastName: '',
            interest: [],
            phone: '',
            email: '',
            message: '',
        }
    }
}
