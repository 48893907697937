import React, { useState } from 'react';
import "./App.scss";
import { Router } from "./containers/Router/Router";
import { SidebarStateContext } from "./context/SidebarStateContext";
import { InterestStateContext } from "./context/InterestStateContext";
import { OffCanvasSidebar } from "./components/OffCanvasSidebar/OffCanvasSidebar";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-44139215-10');

function App() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [interest, setInterest] = useState();
    return (
        <div className={`app-wrapper ${sidebarOpen ? 'sidebar-open' : ''}`}>
            <SidebarStateContext.Provider value={{isOpen: sidebarOpen, toggleOpen: setSidebarOpen}}>
                <InterestStateContext.Provider value={{interest, setInterest}}>
                    <Router/>
                    <OffCanvasSidebar />
                </InterestStateContext.Provider>
            </SidebarStateContext.Provider>
        </div>
    );
}

export default App;
