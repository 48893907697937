import React, { TextareaHTMLAttributes, useState } from "react";
import "./Textarea.scss";
import { FormHelpers } from "../helpers/FormHelpers";

interface IProps extends Omit<TextareaHTMLAttributes<any>, 'onChange'> {
    label: string;
    onChange: (value: string) => void;
}

export function Textarea(props: IProps) {
    const [uniqueId] = useState(FormHelpers.randomString(10));
    const {
        label,
        id,
        onChange,
        ...inputProps
    } = props;

    (inputProps as TextareaHTMLAttributes<any>).id = uniqueId;
    (inputProps as TextareaHTMLAttributes<any>).className = `${inputProps.className ?? ''} ${inputProps.value ? 'filled' :''}`;
    (inputProps as TextareaHTMLAttributes<any>).onInput = (elm) => {
        (elm.target as HTMLTextAreaElement).style.height = '5px';
        (elm.target as HTMLTextAreaElement).style.height = `${(elm.target as HTMLTextAreaElement).scrollHeight + 1}px`;
    }

    (inputProps as TextareaHTMLAttributes<any>).onChange = (event) => {
        onChange(event.target.value);
    }

    const htmlTextarea = React.createElement('textarea', inputProps);
    return (
        <div className="textarea-wrapper" id={id}>
            {htmlTextarea}
            <label htmlFor={uniqueId}>{label}</label>
            <span />
        </div>
    )
}
