import React from "react";
import "./Footer.scss";
import { Menu } from "../Menu/Menu";

interface IProps {

}

export function Footer(props: IProps) {
    return (
        <div className="footer-wrapper">
            <div className="footer-col">
                <div className="footer-col-contents">
                    <b>Contact opnemen</b><br />
                    <br />
                    <span>De Reest 60</span><br />
                    <span>8253PW</span><br />
                    <span>Dronten</span><br />
                    <br />
                    <span>T: 0321 31 77 99</span><br />
                    <span>E: info@visualsystems.nl</span><br />
                </div>
            </div>
            <div className="footer-col">
                <div className="footer-col-contents">
                    <b>Onze software</b><br />
                    <br />
                    <nav>
                        <Menu menuName={'subsites-menu'} className={'footer-menu-wrapper'} />
                    </nav>
                </div>
            </div>
            <div className="footer-col">
                <div className="footer-col-contents">
                    <b>Informatie</b><br />
                    <br />
                    <nav>
                        <Menu menuName={'footer-menu'} className={'footer-menu-wrapper'} />
                    </nav>
                </div>
            </div>
        </div>
    )
}
