import React from "react";

interface IAssets {
    assets?: any[],
    done?: boolean,
    addAsset: (asset: string) => void
    waitAMinute?: boolean
    setWaitAMinute: (value: boolean) => void
}

export const AssetsContext = React.createContext({
    addAsset: () => {},
    done: false,
    setWaitAMinute: () => {}
} as IAssets);