import React, { ReactNode, useEffect, useRef } from "react";
import "./HoveringTiltedBar.scss";

interface IProps {
    rotation?: 'left' | 'right';
    children?: ReactNode | ReactNode[] | string | number;
    beforeColor?: string;
    afterColor?: string;
}

export function HoveringTiltedBar({ children, rotation = 'left', beforeColor, afterColor }: IProps) {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            const div = ref.current as any as HTMLDivElement;
            const prev = div.previousElementSibling as any as HTMLElement;
            const next = div.nextElementSibling as any as HTMLElement;
            div.style.setProperty('--before-background', beforeColor ?? getComputedStyle(prev, 'backgroundColor')?.backgroundColor);
            div.style.setProperty('--after-background', afterColor ?? getComputedStyle(next, 'backgroundColor')?.backgroundColor);
        }
    }, [ref, beforeColor, afterColor]);

    return (
        <div className={`hovering-tilted-bar-wrapper rotation-${rotation}`} ref={ref}>
            <div className="hovering-titled-background" />
            <div className="hovering-titled-bar-contents">
                {children}
            </div>
        </div>
    )
}
