import React, { ReactNode, useContext, useEffect, useRef } from "react";
import "./ProductContent.scss";
import { Button } from "../../../components/Button/Button";
import { SidebarStateContext } from "../../../context/SidebarStateContext";
import { SwitchLottie } from "../../Front/SwitchLottie/SwitchLottie";
import { HoveringTiltedBar } from "../../../components/HoveringTiltedBar/HoveringTiltedBar";
import { Content } from "../../../components/Content/Content";
import Lottie from "react-lottie-player";
import vliegtuigLottie from "../../../resources/lottie/vliegtuig.json";
import Cloud from "../../../resources/svg/badge-cloud.svg";
import Message from "../../../resources/svg/badge-message.svg";
import Plug from "../../../resources/svg/badge-plug.svg";
import { InterestStateContext } from "../../../context/InterestStateContext";
import CalendarLottie from "../../../resources/lottie/calendar.json";

interface IProps {
    slug: string;
    children: ReactNode[];
}

export function ProductContent(props: IProps) {
    const { setInterest } = useContext(InterestStateContext);
    const ulRef = useRef(null);
    let url;
    let interest: string;
    switch (props.slug) {
        case 'cursusplanning':
            url = 'https://cursus-planning.nl';
            interest = 'Cursusplanning';
        break;
        case 'inspecties':
            url = 'https://inspectie-software.nl/';
            interest = 'Inspecties';
        break;
        case 'verhuur':
            url = 'https://www.rentaldynamics.nl/';
            interest = 'Verhuur';
        break;
    }

    useEffect(() => {
        const handleScroll = () => {
            const ul: HTMLUListElement = ulRef.current as any as HTMLUListElement;
            if (ul) {
                const doc = document.documentElement;
                const top = doc.scrollTop  - (doc.clientTop || 0) + doc.clientHeight;
                const offset = ul.offsetTop + ul.offsetHeight;
                ul.classList.toggle('in-viewport', top > offset);

            }
        };
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    });

    return (
        <>
            <div className="product-icons-wrapper">
                {props.children.map((child) => {
                    return (
                        <div className="product-page-contents-wrapper">
                            <div className="page-contents">
                                <div>{child}</div>
                            </div>
                            <div className="page-lottie">
                                <Lottie
                                    loop
                                    animationData={CalendarLottie}
                                    play
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </div>
                    );
                })}
                <ul ref={ulRef}>
                    <li>
                        <img alt={'icon'} src={Cloud} />
                        <span>{props.slug === 'verhuur' ? 'Cloudbased' : 'Webbased'}</span>
                    </li>
                    <li>
                        <img alt={'icon'} src={Message} />
                        <span>Goede support</span>
                    </li>
                    <li>
                        <img alt={'icon'} src={Plug} />
                        <span>API</span>
                    </li>
                </ul>
                <div className="cta-wrapper">
                    <SidebarStateContext.Consumer>
                        {({isOpen, toggleOpen}) => (
                            <Button onClick={() => {
                                setInterest(interest);
                                toggleOpen(!isOpen);
                            }}>Een demo aanvragen</Button>
                        )}
                    </SidebarStateContext.Consumer>
                    <Button type={'hollow'} to={url} target={'_blank'}>Meer info</Button>
                </div>
            </div>


            <HoveringTiltedBar rotation={'right'}>

                <h1>Begin vandaag nog!<br />
                    <small>Honderden gingen u voor!</small></h1>
                <SwitchLottie />
            </HoveringTiltedBar>
            <div className="product-content-blue-part">
                <Content className={'text-center'}>
                    <h1>Ook zo enthousiast?<br />
                        <small>Dat kunnen wij ons voorstellen!</small></h1>
                    <Lottie
                        loop
                        animationData={vliegtuigLottie}
                        play
                        style={{ width: '90%' }}
                    />
                    <SidebarStateContext.Consumer>
                        {({isOpen, toggleOpen}) => (
                            <Button onClick={() => {
                                setInterest(interest);
                                toggleOpen(!isOpen);
                            }}>Begin vandaag</Button>
                        )}
                    </SidebarStateContext.Consumer>
                </Content>
            </div>
        </>
    )
}
