import React from "react";
import "./NotFound.scss";
import Lottie from "react-lottie-player";
import lottie from "../../../resources/lottie/not-found.json";

interface IProps {

}

export function NotFound(props: IProps) {
    return (
        <div className="not-found-wrapper">
            <h1>Helaas werd deze pagina niet gevonden</h1>
            <div className="lottie-wrapper">
                <Lottie
                    loop
                    animationData={lottie}
                    play
                    style={{ width: '90%' }}
                />
            </div>
        </div>
    )
}
