import React from "react";
import "./Logo.scss";

interface IProps {
    size?: 'small' | 'big';
}

export function Logo(props: IProps) {
    const {size = 'small'} = props;

    const classes = ['logo-wrapper', `size-${size}`];

    return (
        <div className={classes.join(' ')}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 109.697 109.697">
                <path id="Path_1" data-name="Path 1"
                      d="M103.542,0H10.164A8.19,8.19,0,0,0,2,8.164v93.369a8.19,8.19,0,0,0,8.164,8.164h93.369a8.19,8.19,0,0,0,8.164-8.164V8.164A8.176,8.176,0,0,0,103.542,0ZM17.194,72.534a26.038,26.038,0,0,0,2.112,2.4A25.063,25.063,0,1,0,54.75,39.485a24.741,24.741,0,0,0-2.4-2.112A26.323,26.323,0,1,1,17.194,72.534Zm29.063,24.4A35.609,35.609,0,0,1,34.482,94.95a34.09,34.09,0,1,0-.009-67.634A35.807,35.807,0,1,1,46.257,96.934ZM84.279,83.989a41.543,41.543,0,0,1-11.336,8.073A39.727,39.727,0,1,0,20.568,32.528a40.925,40.925,0,0,0-3.355,3.8A41.722,41.722,0,1,1,84.279,83.989Z"
                      transform="translate(-2)" fill="#390090"/>
            </svg>
        </div>
    )
}
