import React, { ReactNode, useContext, useEffect, useState } from "react";
import "./Page.scss"
import parse, { domToReact } from 'html-react-parser';
import { PageHeader } from "./PageHeader/PageHeader";
import { AssetsContext } from "../../context/AssetsContext";
import { HelmetTitle } from "../../components/HelmetTitle/HelmetTitle";
import { IPage } from "squde-wp-api/IPage";
import { get, last } from "lodash";
import { wp } from "../../Factories/Wordpress";
import { useLocation } from "react-router-dom";
import { ProductContent } from "./ProductContent/ProductContent";
import { NotFound } from "./NotFound/NotFound";
import ReactGA from "react-ga";
import { Button } from "../../components/Button/Button";
import { SidebarStateContext } from "../../context/SidebarStateContext";
import { InterestStateContext } from "../../context/InterestStateContext";

interface IProps {
    type?: string
}

export function Page(props: IProps) {
    let location = useLocation();
    const { setInterest } = useContext(InterestStateContext);
    const [lastSlug, setLastSlug] = useState<string>('');
    const [page, setPage] = useState<IPage>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const slug: string = last(location.pathname.split('/').filter((slug) => slug.length > 0)) ?? '';
    if (slug) {
        ReactGA.pageview(location.pathname);
    }

    useEffect(() => {
        if (slug !== lastSlug) {
            setIsLoading(true);
            const endpoint = slug ? (() => {
                return props.type ? get(wp(), props.type) : wp().page;
            })().get({slug}) : wp().frontpage.get();
            endpoint.then((result: IPage|undefined) => {
                if (result) {
                    setPage(result);
                } else {
                    setPage(undefined);
                }
            }).finally(() => {
                setLastSlug(slug);
                setIsLoading(false);
            })
        }
    }, [lastSlug, props.type, slug]);

    const parseOptions = {
        replace: (props: any) => {
            const {attribs, children, name} = props;

            if (!attribs) return;

            switch (name) {
                case 'a':
                    const contents = domToReact(children);
                    if (typeof contents === 'string' && contents.split('contact').length > 1) {
                        // @ts-ignore
                        return <SidebarStateContext.Consumer>
                            {({isOpen, toggleOpen}) => (
                                React.createElement('a', {
                                    ...attribs,
                                    onClick: (e: any) => {
                                        e.preventDefault();
                                        toggleOpen(!isOpen);
                                    }
                                }, domToReact(children))
                            )}
                        </SidebarStateContext.Consumer>;
                    }
                return React.createElement('a', attribs, domToReact(children));
            }
        }
    }


    return <AssetsContext.Consumer>
        {({setWaitAMinute}) => {
            if (!page && !isLoading) {
                setTimeout(() => setWaitAMinute(false));
                return <NotFound />
            }
            if (isLoading || !page) {
                setTimeout(() => setWaitAMinute(true));
                return null;
            }
            setTimeout(() => setWaitAMinute(false));

            let content = parse(page.content, parseOptions);
            let after: ReactNode[] = [<></>];
            if (page.template === 'page-product.php') {
                const p = page.content.split('<!--nextpage-->');
                const [first, ...rest] = p;
                content = parse(first, parseOptions);
                after = rest.map((part) => {
                    return parse(part, parseOptions);
                });
            }

            const templateCode: string|ReactNode = (() => {
                switch (page.template) {
                    case 'page-product.php':
                        return <ProductContent slug={page.slug}>{after}</ProductContent>
                    default:
                        return '';
                }
            })();

            const image = page?.featured_image?.full ?? `https://picsum.photos/1920?t=${Math.random() * 1000}`;

            return (
                <>
                    <HelmetTitle/>

                    <div className={`page-wrapper ${((page.template || '').split('.php'))[0]}`}>
                        <PageHeader><img alt={'dummy'} src={image} /></PageHeader>
                        <div className="page-contents-wrapper">
                            <div className="page-contents">
                                <h1>{page.title}</h1>
                                <div>{content}</div>
                            </div>
                        </div>

                        {templateCode}

                    </div>

                </>
            )
        }}
    </AssetsContext.Consumer>;
}
