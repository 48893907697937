import React, { DOMAttributes, HTMLAttributes } from "react";

export function WavedBackground(props: HTMLAttributes<DOMAttributes<any>>) {
    const {
        style = {},
        children,
        ...rest
    } = props;

    style.backgroundPosition = "center center";
    style.backgroundRepeat = "no-repeat";
    style.backgroundSize = "cover";
    if (props.className?.split(' ').includes('style-small')) {
        style.height = '100vh';
        const colorA = 'rgb(249, 246, 253)';
        const colorB = 'rgb(243, 234, 255)';
        style.backgroundImage = `url('data:image/svg+xml;utf8,<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1919.3 1080"><path style="fill: ${colorA};" d="M1920,556V713.42h0c-147.13,0-278.61,64.65-365.54,166.1a459.69,459.69,0,0,1-29.9,38.89c-86.93,101.45-218.41,166.1-365.55,166.1a494.17,494.17,0,0,1-79.65-6.43q-20.14-3.28-39.7-8.19a494.05,494.05,0,0,0-79.64-6.43c-149.37,0-282.6,66.62-369.47,170.73q-10.46,14.76-22.07,28.66C481.59,1367,348.36,1433.58,199,1433.58A486.63,486.63,0,0,1,.7,1391.77V556Z" transform="translate(-0.7 -556)"/><path style="fill: ${colorB} ;" d="M1920,713.42V1636H.7V1391.77A486.63,486.63,0,0,0,199,1433.58c149.36,0,282.59-66.62,369.46-170.73q11.61-13.9,22.07-28.66c86.87-104.11,220.1-170.73,369.47-170.73a494.05,494.05,0,0,1,79.64,6.43q19.56,4.9,39.7,8.19a494.17,494.17,0,0,0,79.65,6.43c147.14,0,278.62-64.65,365.55-166.1a459.69,459.69,0,0,0,29.9-38.89c86.93-101.45,218.41-166.1,365.54-166.1Z" transform="translate(-0.7 -556)"/></svg>')`;
    } else {
        style.height = '300vh';
        const colorA = 'rgb(57,0,144)';
        const colorB = 'rgb(244,234,255)';
        style.backgroundImage = `url('data:image/svg+xml;utf8,<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920.7 3240"><defs></defs><rec  style="fill: ${colorA}" x="0.7" width="1920" height="3240"/><path style="fill: ${colorB}" d="M1920,713.42c-147.13,0-278.61,64.65-365.54,166.1a459.69,459.69,0,0,1-29.9,38.89c-86.93,101.45-218.41,166.1-365.55,166.1a494.17,494.17,0,0,1-79.65-6.43q-20.14-3.28-39.7-8.19a494.05,494.05,0,0,0-79.64-6.43c-149.37,0-282.6,66.62-369.47,170.73q-10.46,14.76-22.07,28.66C481.59,1367,348.36,1433.58,199,1433.58A486.75,486.75,0,0,1,0,1391.46v1093a486.75,486.75,0,0,0,199,42.12c149.36,0,282.59-66.62,369.46-170.73q11.6-13.89,22.07-28.66c86.87-104.11,220.1-170.73,369.47-170.73a494.05,494.05,0,0,1,79.64,6.43q19.53,4.91,39.7,8.19a494.17,494.17,0,0,0,79.65,6.43c147.14,0,278.62-64.65,365.55-166.1a459.69,459.69,0,0,0,29.9-38.89c86.93-101.45,218.41-166.1,365.54-166.1h0v-1093Z"/></svg>')`;
    }

    return React.createElement('div', {
        style,
        ...rest,
    }, children);
}
