export function setLocalStorage(key: string, value: any) {
    window.localStorage.setItem(localStorageKey(key), JSON.stringify(value));
}
export function getLocalStorage(key: string, defaultValue = undefined) {
    const result = window.localStorage.getItem(localStorageKey(key));
    if (result) {
        return JSON.parse(result);
    }
    return defaultValue;
}

function localStorageKey(key: string) {
    return `${window.location.host}_${key}`;
}
