import React, { InputHTMLAttributes, useState } from "react";
import "./Input.scss";
import { FormHelpers } from "../helpers/FormHelpers";

interface IProps extends Omit<InputHTMLAttributes<any>, 'onChange'> {
    label: string;
    onChange: (value: string) => void;
}

export function Input(props: IProps) {
    const [uniqueId] = useState(FormHelpers.randomString(10));
    const {
        label,
        id,
        onChange,
        ...inputProps
    } = props;

    (inputProps as InputHTMLAttributes<any>).id = uniqueId;
    (inputProps as InputHTMLAttributes<any>).onChange = (event) => {
        onChange(event.target.value);
    }

    const htmlInput = React.createElement('input', inputProps);
    return (
        <div className="input-wrapper" id={id}>
            {htmlInput}
            <label htmlFor={uniqueId}>{label}</label>
            <span />
        </div>
    )
}
