import React from "react";
import "./OffCanvasSidebar.scss";
import { ContactForm } from "./ContactForm/ContactForm";
import { SidebarStateContext } from "../../context/SidebarStateContext";

interface IProps {

}

export function OffCanvasSidebar(props: IProps) {

    return (
        <div className="off-canvas-sidebar-wrapper">
            <SidebarStateContext.Consumer>
                {({isOpen, toggleOpen}) => (
                    <div className="sidebar-close-button" onClick={() => toggleOpen(!isOpen)}>
                        <span />
                        <span />
                        <span />
                    </div>
                )}

            </SidebarStateContext.Consumer>
            <ContactForm />
        </div>
    )
}
