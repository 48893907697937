import React, { SelectHTMLAttributes, useEffect, useState } from "react";
import "./Select.scss";
import { clone } from "lodash";

interface IProps extends Omit<SelectHTMLAttributes<any>, 'onChange'> {
    label: string;
    onChange: (value: string | string[]) => void;
    value: string | string[];
    options: string[];
}

export function Select(props: IProps) {
    const [value, setValue] = useState<string|string[]>(props.value);
    const {
        label,
        options,
        onChange,
        ...selectProps
    } = props;

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const toggleSelection = (option: string) => {
        let newState: string|string[] = clone(value);
        if (selectProps.multiple) {
            if (newState.includes(option)) {
                newState = (newState as string[]).filter((opt) => opt !== option);
            } else {
                (newState as string[]).push(option);
            }
        } else {
            newState = option;
        }
        onChange(newState);
    }

    return (
        <div className={`select-wrapper ${props.disabled ? 'disabled' : ''}`}>
            <div className="select-box-wrapper" data-value={selectProps.value || selectProps.placeholder || ''}>
                {!selectProps.multiple ? <span>{selectProps.value || selectProps.placeholder || ''}</span> : <></>}
                <ul>
                    {options.map((option: string, index: number) => {
                        let selected;
                        if (typeof value === 'string') {
                            selected = option === value;
                        } else {
                            selected = value.includes(option);
                        }
                        return <li key={index} onClick={() => toggleSelection(option)} className={selected ? 'selected' : ''}>{option}</li>;
                    })}
                </ul>
            </div>
            <div className="select-box-label">{label}</div>
        </div>
    )
}
