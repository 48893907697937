import React from "react";
import "./WavingGirl.scss";
import meisjeLottie from "../../../resources/lottie/meisje.json";
import Lottie from "react-lottie-player";

interface IProps {

}

export function WavingGirl(props: IProps) {
    return (
        <div className="waving-girl-wrapper">
            <div className="waving-girl-container">
                <div className="lottie-girl-wrapper">
                    <Lottie
                        loop
                        animationData={meisjeLottie}
                        play
                        className={'lottie-girl'}
                    />
                </div>
                <div className="cilinder-wrapper">
                    <div className="cilinder" />
                </div>
            </div>
        </div>
    )
}
