import Wordpress from "squde-wp-api";

export interface ISlugResult {
    ID: string
    type: string
}

class CustomWordpress extends Wordpress {

    get slug() {
        const api = this;
        return {
            get: (params: { [s: string]: string; } = {}, signal: any = null): Promise<ISlugResult> => {
                return api.get('slug/?' + api.buildQuery(params), signal)
                    .then((result: any) => result);
            }
        }
    }
}
export function wp() {
    const endpoints = {
        dev: 'http://visualsystems.local',
        test: 'https://2022.visualsystems.nl',
        prod: 'https://visualsystems.nl'
    };
    let endpoint;
    const host = window.location.host;
    if (host === 'visualsystems.nl') {
        endpoint = endpoints.prod;
    } else if (host === '2022.visualsystems.nl') {
        endpoint = `${endpoints.test}`;
    } else {
        endpoint = endpoints.dev
    }
    return new CustomWordpress(endpoint + "/wp-json/wp/v2/");
}
