import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { Front } from "../../pages/Front/Front";
import { Page } from "../../pages/Page/Page";
import { TopBar } from "../../components/TopBar/TopBar";
import { Footer } from "../../components/Footer/Footer";
import { SimpleCookieNotice } from "../../components/SimpleCookieNotice/SimpleCookieNotice";
import { ScrollToTop } from "../../components/ScrollToTop/ScrollToTop";


interface IProps {

}

export function Router(props: IProps) {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <TopBar />
            <Routes>
                {/*<Route path="/" element={<Front />} />*/}
                <Route path="/" element={<Front />} />
                <Route path="/home" element={<Front />} />
                <Route path="/*" element={<Page />} />
            </Routes>
            <Footer />
            <SimpleCookieNotice />
        </BrowserRouter>
    )
}
