import React, { useContext, useEffect, useState } from "react";
import "./ContactForm.scss";
import { ContactFormHelper, ContactFormState } from "./helpers/ContactFormHelper";
import { Input } from "../../Form/Input/Input";
import { set } from "lodash";
import { Select } from "../../Form/Select/Select";
import { Textarea } from "../../Form/Textarea/Textarea";
import { Button } from "../../Button/Button";
import { wp } from "../../../Factories/Wordpress";
import { SidebarStateContext } from "../../../context/SidebarStateContext";
import { InterestStateContext } from "../../../context/InterestStateContext";

interface IProps {

}



export function ContactForm(props: IProps) {
    const { interest } = useContext(InterestStateContext);
    const { toggleOpen } = useContext(SidebarStateContext);
    const [status, setStatus] = useState<'open'|'sending'|'sent'>('open');
    const [formState, setFormState] = useState<ContactFormState>(ContactFormHelper.createFormState());
    const [fakeProgress, setFakeProgress] = useState(0);
    const [hasErrors, setHasErrors] = useState(false);
    const [dots, setDots] = useState('.');

    // Initiate the fake progress for the button
    useEffect(() => {
        if (status === 'open') {
            setFakeProgress(0);
        } else if (status === 'sending') {
            // give it 5 seconds to reach 90
            // refresh every 0.5 seconds
            // add 0.9 10x
            setFakeProgress(0.9);
        } else if (status === 'sent') {
            setFakeProgress(100);
        }
    }, [status]);

    // Increment the fake progress up to 90 over time
    useEffect(() => {
        if (fakeProgress > 0 && fakeProgress < 90) {
            setTimeout(() => {
                setFakeProgress((prev) => {
                    if (prev > 0 && prev < 90) {
                        return prev + 0.9;
                    }
                    return prev;
                });
            }, 50);
        }
    }, [fakeProgress]);

    // Change the dots when status is sending
    useEffect(() => {
        if (status === 'sending') {
            let newDots = `${dots}.`;
            if (newDots.length > 3) {
                newDots = '';
            }
            setTimeout(() => {
                setDots(newDots);
            }, 500);
        }
    }, [status, dots]);


    const handleChange = (forKey: string) => {
        return (value: string | string[]) => {
            setFormState((prevState) => {
                const newState = {...prevState};
                set(newState, forKey, value);
                return newState;
            });
        }
    }

    useEffect(() => {
        if (interest) {
            handleChange('interest')([interest]);
        }
    }, [interest])

    const submitForm = () => {
        const {
            firstName,
            lastName,
            ...rest
        } = formState;
        const data: {[ key: string]: any } = {
            form: 'Contactform',
            name: `${firstName} ${lastName}`,
            ...rest,
        }
        setHasErrors(false);
        setStatus('sending');
        wp().submissions.post(data).then(() => {
            setStatus('sent');
            setTimeout(() => {
                toggleOpen(false);
                setStatus('open');
            }, 5000);
        }).catch(() => {
            setStatus('open');
            setHasErrors(true);
        });
    }

    return (
        <div className={`contact-form-wrapper ${status}`}>
            <h1>Contact</h1>

            {hasErrors ? (
                <p style={{color: 'red'}}>Controleer a.u.b. de velden</p>
            ) : <></>}

            <Input label={'Bedrijfsnaam'} type={'text'} onChange={handleChange('companyName')} value={formState.companyName} />
            <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px'}}>
                <Input label={'Voornaam'} type={'text'} onChange={handleChange('firstName')} value={formState.firstName} />
                <Input label={'Achternaam'} type={'text'} onChange={handleChange('lastName')} value={formState.lastName} />
            </div>
            <Select
                label={'Mijn interesses'}
                onChange={handleChange('interest')}
                value={formState.interest}
                options={['Cursusplanning', 'Inspecties', 'Verhuur']}
                multiple={true}
            />
            <Input label={'Email'} formNoValidate={true} type={'email'} onChange={handleChange('email')} value={formState.email} />
            <Input label={'Telefoonnummer'} formNoValidate={true} type={'tel'} onChange={handleChange('phone')} value={formState.phone} />
            <Textarea label={'Bericht'} onChange={handleChange('message')} value={formState.message} />
            <br />
            <br />
            {status === 'sending' ? (
                <p>Bericht wordt verzonden{dots}</p>
            ) : <></>}
            {status === 'sent' ? (
                <>
                    <p>Bericht ontvangen!</p>
                    <p>Wij nemen z.s.m. contact met u op.</p>
                </>
            ) : <></>}
            <Button onClick={submitForm} disabled={status !== 'open'} progress={fakeProgress}>Verzenden</Button>
        </div>
    )
}
