import React from "react";
import "./Content.scss";

export function Content(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    let {
        className = '',
        children,
        ...rest
    } = props;

    if (className.search('content-wrapper') < 0) {
        className = `${className} content-wrapper`;
    }

    return React.createElement('div', {
        ...rest,
        className,
    }, children);
}
