import React, { useEffect, useState } from "react";
import "./SimpleCookieNotice.scss";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

export function SimpleCookieNotice() {
    const [accepted] = useState({
        functional: true,
        analytics: true,
    });
    const [didAccept, setDidAccept] = useState(false);

    useEffect(() => {
        if (didAccept) {
            Cookies.set('accepted-functional', 'true', { expires: 365 });
            if (accepted.analytics) {
                Cookies.set('accepted-analytics', 'true', { expires: 365 });
            } else {
                Cookies.remove('accepted-analytics')
            }
        }
    }, [didAccept, accepted]);

    const acceptAll = () => {
        setDidAccept(true);
    }

    if (didAccept || !!Cookies.get('accepted-functional')) {
        return <></>
    }

    return (
        <div className="simple-cookie-notice-wrapper">
            <div className="contents-wrapper">
                <h6>Wij maken gebruik van cookies</h6>
                <p>Visual Systems gebruikt Functionele en Analytische cookies voor website optimalisatie en statistieken. <Link to={'/cookiebeleid'}>Meer info</Link></p>
            </div>
            <div className="close-button" onClick={() => {
                acceptAll();
            }} />
        </div>
    )
}
