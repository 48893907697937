import React from "react";
import "./ProductBar.scss";
import { Button } from "../../../components/Button/Button";
import ClassRoom from "../../../resources/svg/classroom.svg";
import Office from "../../../resources/svg/office.svg";
import Rental from "../../../resources/svg/rental.svg";

interface IProps {
    product: 'cursusplanning' | 'inspecties' | 'verhuur';
    direction?: 'ltr' | 'rtl';
}

interface IProductInfo {
    title: string;
    content: string;
    link: string;
    svg: any;
}

export function ProductBar(props: IProps) {
    const {
        product,
        direction = 'ltr',
    } = props;

    const classes = ['product-bar-wrapper', `direction-${direction}`];


    let info: IProductInfo;

    switch (product) {
        case 'cursusplanning':
            info = {
                title: 'Cursusplanning',
                content: `<p>Visual Cursusplanning Online is de meest gebruiksvriendelijke cursusadministratie software ooit! U werkt vanuit slechts 3 menu’s. Deze cursussoftware heeft geen dure consultants of dikke handleidingen nodig.`,
                link: '/cursusplanning',
                svg: ClassRoom,
            };
        break;
        case 'inspecties':
            info = {
                title: 'Inspecties',
                content: `<p>Met deze servicesoftware houdt u eenvoudig bij welke apparaten bij welke klanten onderhoud of controle nodig hebben. Dit plant u eenvoudig in via ‘drag-and-drop’.</p>`,
                link: '/inspecties',
                svg: Office,
            };
        break;
        case 'verhuur':
            info = {
                title: 'Verhuur',
                content: `<p>Onze verhuursoftware is de <span style="white-space: nowrap; text-decoration: underline">nr. 1 verhuursoftware van Nederland en België</span>. Wij leveren een betaalbare en volledig vanuit de praktijk ontwikkelde Cloud oplossing voor verhuurbedrijven.</p>`,
                link: '/verhuur',
                svg: Rental,
            };
        break;
    }

    return (
        <div className={classes.join(' ')}>
            <div className="product-bar-contents">
                <div className="product-bar-description">
                    <h1>{info.title}</h1>
                    <div dangerouslySetInnerHTML={{__html: info.content}} />
                    <Button to={info.link}>Meer info</Button>
                </div>
                <div className="product-bar-illustration">
                    <img src={info.svg} alt="svg"/>
                </div>
            </div>
        </div>
    )
}
