import React from "react";
import "./Button.scss";
import { Link } from "react-router-dom";
import { OutboundLink } from "react-ga";

interface IProps {
    children: string;
    to?: string;
    onClick?: () => void;
    disabled?: boolean;
    progress?: number;
    target?: string;
    type?: 'hollow' | 'filled';
}

export function Button(props: IProps) {

    if (props.to) {
        if (props.to.includes('://')) {
            return  <OutboundLink
                eventLabel={`Go to ${props.to}`}
                to={props.to}
                target={'_blank'}>
                <Button type={props.type}>{props.children}</Button>
            </OutboundLink>
        }
        return <Link
            to={props.to}
            target={props.target}>
            <Button
                type={props.type}
            >{props.children}</Button></Link>
    }
    const buttonClass = ['button', ...[props.type]];
    return (
        <button className={buttonClass.join(' ')}
                onClick={props.onClick}
                disabled={props.disabled}
                data-progress={props.progress ? Math.round(props.progress) : undefined}
        >
            {props.children}
        </button>
    )
}
